const ductContentArabic = {
    title: "الدكت ”Duct“",
    breadcrumb: [
        { name: "Etqan Nafal", className: "breadcrumb-item", link: "/" },
        { name: "الصفحة", className: "breadcrumb-item", link: "#" },
        { name: "الدكت", className: "breadcrumb-item active" }
    ],
    buttons: [
        { to: "/contact-us", label: "اتصل بنا", className: "btn btn-primary mt-2 me-2" },
        { to: "/services", label: "خدمات", className: "btn btn-outline-primary mt-2" },
    ],
    introductionTitle: "مقدمة",
    introduction: "العنصر الرئيسي لتوزيع الهواء في أنظمة التكييف والتهوية",
    tableOfContentsTitle: "جدول المحتويات",
    tableOfContents: [
        "ما هو الدكت ”Duct“؟",
        "مكونات وملحقات الدكت ”Duct“",
        "أنواع الدكت”Duct“",
        "المصنعة للدكت ”Duct“",
        "العزل الحراري والصوتي",
        "تصميم الدكت ”Duct“",
        "تأثير مجاري الهواء الدكت ”Duct“ على كفاءة النظام",
        "لأي استفسارات اخرى!",
    ],
    whatIsADuct: {
        title: "ما هو الدكت ”Duct“؟",
        text1: "هي عبارة عن مجاري للهواء وتسمى الدكت”Duct” تستخدم لتوجيه وتوزيع الهواء بالكميات المحددة في المباني وفقا للتصميم.",
        text2: "يعمل الدكت ”Duct“ على نقل الهواء من أنظمة التكييف أو التهوية إلى الأماكن المطلوبة، سواء كان ذلك لتوزيع الهواء المكيف في الغرف المختلفة، أو لسحب الهواء المحمل بالروائح غير المرغوبة او الملوثة أو الغازات الضارة من المناطق الصناعية او غيرها لكثير من الاستخدامات.",
    },
    components: {
        title: "مكونات وملحقات الدكت ”Duct“",
        preventiveMaintenance: {
            description: "تتكون شبكة مجاري الهواء الدكت ”Duct“ من عدة مكونات مختلفة المادة والدور لكل مكون، لإتمام عملية تدفق الهواء بطرق محدده داخل شبكة توزيع الهواء بشكل فعال.",
            types: [
                "الكانفاس (Canvas) وهو عبارة عن وصلة بأطراف معدنية يتوسطها نوع من الجلد تساعد على امتصاص الاهتزازات الناتجة عن الماكينة وعدم نقلها الى “الدكت”",
                "الوصلات المرنة (Flexible) تربط “الدكت” بصناديق توزيع الهواء وتكون معزولة بالصوف الزجاجي وتمتاز بمرونتها وسهولة تشكيلها وتمرريها بالأماكن الضيقة.",
                "صندوق توزيع الهواء (Plenum Box) وهو يربط الوصلات المرنة بفتحات الهواء وأيضا معزول من الداخل.",
                "الدنبر (Dumber) وتستخدم في التحكم في سرعة سريان الهواء وكميته وتقسيمه ويمكن التحم بها يدويا او آليا ولها عدة أنواع",
                "الجريلات (Grilles) فتحات مخارج الهواء وتأتي بعدة أنواع “جانبية، طولية، مربعة، دائرية، جيت”",
                "مخمدات أو كاتمات الصوت (Sound Attenuator) وتعمل على تقليل وخفض الصوت الناتج من مراوح دفع الهواء ويجب ان يتم اضافة الهبوط في الضغط الناتج من تركيبها الى حسابات الهبوط في الضغط static pressure” ” لمروحة الهواء المستخدمة."
            ]
        }
    },
    typesOfDuct: {
        title: "أنواع الدكت”Duct“",
        tasks: [
            "يتم تصنيع الدكت ”Duct“ بعدة اشكال لتتناسب مع حجم ونوع المشروع ومنها:",
            "الدكت ”Duct“ المربع او المستطيل، وتستعمل بشكل أوسع انتشاراً نظرا لسهولة اخفائها بالأسقف المستعارة.",
            "الدكت ”Duct“ الدائري، وتصنع بحسب القطر المطلوب وغالبا ما تستخدم بالأماكن التي لا يوجد بها أسقف جبسية.",
            "الدكت ”Duct“ البيضاوي، وتشبه بعض الشيء الدائرية مع اختلاف طريقة تصنيعها.",
        ]
    },
    materialsManufacturedForDuct: {
        title: "المصنعة للدكت ”Duct“",
        text: "تٌصنع معظمها من الواح الصاج المجلفن غير القابل للتأكل والصداء، وايضاً لصلابته وسهولة تشكيله، وتصنع أيضا من الألومنيوم والاستيل ومؤخراً من القماش والفايبر جلاس، ولكن لتطبيقات محددة، ويعتمد اختيار الدكت ”Duct“بحسب نوع الاستخدام ومتطلبات المشروع.",
    },
    thermal: {
        title: "العزل الحراري والصوتي",
        text: "تعزل مجاري الهواء الدكت ”Duct“ بالصوف الزجاجي “الفايبر جلاس” من الخارج والداخل لمنع فقدان الحرارة وتقليل انتقال الضوضاء، ولكن بالداخل يتم تغطيته بطبقة لحمايته من الرطوبة ومنع انتشار ذرات الفايبر بالهواء، والمحافظة على درجة الحرارة المندفعة من المراوح مروراً بمجري الهواء والوصلات المرنة ثم الصناديق ووصولا لمخارج الهواء “الجريلات”",
    },
    ductDesign: {
        title: "تصميم الدكت ”Duct“",
        text1: "يتم تشكيل الصاج وتصميم مجاري الهواء الدكت ”Duct“ وملحقاته بناءً على الاحتياجات الهندسية والتقنية للمشروع وتحت اشراف مهندسين ذو خبرة، وطبقا للمقاسات الموضحة بالمخططات التصميمية، وبالمقاييس والسماكات المبينة بالأكواد العالمية المعمول بها (SMACNA) وهي الرابطة العالمية لمقاولي الصاج وتكييف الهواء،",
        text2: "تُعد موازنة الهواء خطوة أساسية في ضمان عمل أنظمة التكييف والتهوية بكفاءة مثالية حيث يتم عمل اختبار لتوازن ومعايرة تدفق الهواء للتأكد من توزيع الهواء بالتساوي والتحكم في الضغط في جميع أنحاء النظام."
    },
    effect: {
        title: "تأثير مجاري الهواء الدكت ”Duct“ على كفاءة النظام",
        points: [
            { title: "توجيه الهواء", text: "يساهم التصميم الجيد لمجاري الهواء في توجيه الهواء بفعالية، مما يحسن كفاءة نظام التكييف والتهوية." },
            { title: "تقليل فقدان الضغط", text: "الانحناءات والتفرعات الصحيحة تساعد في تقليل فقدان الضغط وتحسين أداء النظام." },
            { title: "صيانة سهلة", text: "يمكن أن يؤدي تصميم مجاري الهواء إلى جعل عمليات الصيانة والتنظيف أكثر سهولة، مما يحسن عمر النظام." },
        ]
    },
    otherInquiries: {
        title: "لأي استفسارات اخرى!",
        text1: "مجاري الهواء الدكت ”Duct“ ليست مجرد أنابيب وتجهيزات، بل تعتبر جزءًا أساسيًا من نظام التكييف والتهوية. من خلال اعتماد مواد عالية الجودة وتصميم دقيق،",
        text2: "نسعى دائماً الى تقديم حلول وخدمات فريدة مصممه خصيصاَ وفق احتياجات عملائنا، لتبدأ رحلتنا من حيث انتهى الاخرون لنقدم لعملائنا “حلول مبتكرة …. لحياة أسهل",
        text3: "لا تترد لطلب عرض سعر، أو أي استفسار عن أنظمة التكييف المختلفة وايهما أنسب لمشروعك، مهندسينا في انتظار طلبك، ومساعدتك. فقط اضغط على زر “اتصل بنا”، او تحدث مع مهندسنا عبر واتساب",
    }
}

export { ductContentArabic }