const ductContent = {
    title: "Duct",
    breadcrumb: [
        { name: "Etqan Nafal", className: "breadcrumb-item", link: "/" },
        { name: "Page", className: "breadcrumb-item", link: "#" },
        { name: "Duct", className: "breadcrumb-item active" }
    ],
    buttons: [
        { to: "/contact-us", label: "Contact Us", className: "btn btn-primary mt-2 me-2" },
        { to: "/services", label: "Services", className: "btn btn-outline-primary mt-2" },
    ],
    introductionTitle: "Introduction",
    introduction: "the main element of air distribution in HVAC systems.",
    tableOfContentsTitle: "Table of contents",
    tableOfContents: [
        "What is a “Duct”?",
        "Components and accessories of “Duct”",
        "Types of “Duct”",
        "Materials manufactured for “Duct”",
        "Thermal and sound insulation",
        "Duct design",
        "Effect of air “Duct” on the efficiency of the system",
        "For any other inquiries",
    ],
    whatIsADuct: {
        title: "What is a “Duct”?",
        text1: "They are air ducts, used to direct and distribute air in specified quantities in buildings according to the design.",
        text2: "“Duct” works to transfer air from air conditioning or ventilation systems to the required places, whether it is to distribute air conditioning in different rooms, or to withdraw air loaded with unwanted odors, polluting or harmful gases from industrial areas or others for many uses.",
    },
    components: {
        title: "Components and accessories of “Duct”",
        preventiveMaintenance: {
            description: "ductair duct network “DUCT” consists of several components of different material and role for each component, to complete the air flow process in specific ways within the air distribution network effectively.",
            types: [
                "(Canvas) it is a connection with metal limbs mediated by a kind of skin that helps to absorb the vibrations generated by the machine and not transfer them to the ”DACT”.",
                "(Flexible) the “dactyl” is connected to the air distribution boxes and is insulated with glass wool and is characterized by its flexibility, easy to form and pass through narrow places.",
                "(Plenum Box) it connects flexible connections to air vents and is also insulated from the inside.",
                "(Dumber) are used to control the speed of air flow, quantity and division and can be loaded manually or automatically and have several types.",
                "(grills) air outlet vents come in several types “side, longitudinal, square, round, jet”.",
                "(Sound Attenduator) it works to reduce and reduce the sound produced by air pushing fans and the drop in pressure resulting from its installation should be added to the calculations of the drop in static pressure” ” for the air fan used."
            ]
        }
    },
    typesOfDuct: {
        title: "Types of “Duct”",
        tasks: [
            "“Duct” is manufactured in several forms to suit the size and type of the project, including:",
            "“Duct” square or rectangular, are more widely used due to their ease of concealment with false ceilings.",
            "”Duct” round, are made according to the required diameter and are often used in places where there are no gypsum ceilings.",
            "”Duct” Oval, and somewhat similar to circular with a difference in the method of their manufacture.",
        ]
    },
    materialsManufacturedForDuct: {
        title: "Materials manufactured for “Duct”",
        text: "Most of them are made of galvanized sheet metal that is not corrode and Rust, also for its hardness and ease of forming, and are also made of aluminum, steel and more recently of fabric and fiberglass, but for specific applications, the choice of “Duct”depends on the type of Use and the requirements of the project.",
    },
    thermal: {
        title: "Thermal and sound insulation",
        text: "Duct air ducts are insulated with Fiberglass Glass Wool from the outside and inside to prevent heat loss and reduce noise transmission, but inside it is covered with a layer to protect it from moisture and prevent the spread of fiber atoms in the air, and maintain the temperature rushing from the fans through the air duct and flexible connections, then boxes and up to the air outlets“",
    },
    ductDesign: {
        title: "Duct design",
        text1: "The sheet and the design of duct air ducts and its accessories are formed based on the engineering and technical needs of the project and under the supervision of experienced engineers, according to the sizes indicated by the design schemes, and by the standards and thicknesses indicated by the applicable international codes (SMACNA) which is the World Association of sheet metal and air conditioning contractors،",
        text2: "Air balancing is an essential step in ensuring that HVAC and ventilation systems work with optimal efficiency, where a test is made for the balance and calibration of air flow to ensure that air is evenly distributed and pressure is controlled throughout the system."
    },
    effect: {
        title: "Effect of air “Duct” on the efficiency of the system",
        points: [
            { title: "air routing", text: "the good design of the air ducts contributes to the effective routing of air, which improves the efficiency of the air conditioning and ventilation." },
            { title: "reduce pressure loss", text: "correct bends and branches help reduce pressure loss and improve system performance." },
            { title: "easy maintenance", text: "duct design can make maintenance and cleaning operations easier, which improves the life of thesystem." },
        ]
    },
    otherInquiries: {
        title: "For any other inquiries",
        text1: "Air ducts are not just pipes and fittings, they are an essential part of the HVAC system. By adopting high quality materials and delicate design،",
        text2: "We always strive to provide unique solutions and services tailored to the needs of our customers, to start our journey from where others ended to offer our customers innovative solutions.... For an easier life",
        text3: "Don't hesitate to request a quote, or any inquiry about different HVAC systems and whichever is most suitable for your project, our engineers are waiting for your request, and help you. Just click on the “Contact” Us button, or talk to our engineer via WhatsApp",
    }
}

export { ductContent }