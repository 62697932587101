const termsContentArabic = {
    title: "شروط الخدمة",
    breadcrumb: [
        { name: "Etqan Nafal", className: "breadcrumb-item", link: "/" },
        { name: "صفحة", className: "breadcrumb-item", link: "#" },
        { name: "شروط", className: "breadcrumb-item active" }
    ],
    buttons: [
        { to: "/contact-us", label: "اتصل بنا", className: "btn btn-primary mt-2 me-2" },
        { to: "/services", label: "خدمات", className: "btn btn-outline-primary mt-2" },
    ],
    welcome: {
        title: "مرحبا بكم في اتقان نفال للتكييف :",
        content: "اكتشف أفضل خدمات تكييف الهواء في اتقان نفال للتكييف. نحن ملتزمون بتوفير حلول راحة لا مثيل لها لمساحاتك الداخلية.",
    },
    commitment: {
        title: "التزامنا بالخدمة :",
        content: "في شركة اتقان نفال للتكييف، نتعهد بتقديم خدمات تكييف الهواء الموثوقة والفعالة، والمصممة خصيصًا لتلبية احتياجاتك الفريدة. التزامنا الثابت هو تحسين أداء وعمر أنظمة تكييف الهواء (HVAC)، مما يضمن راحتك الداخلية طوال العام.",
    },
    restrictions: {
        title: "قيود الخدمة :",
        content: "لضمان جودة وسلامة خدمات التدفئة والتهوية وتكييف الهواء (HVAC)، يُحظر عليك بشكل خاص المشاركة في الأنشطة التالية :",
        listItems: [
            'محاولة إصلاح أنظمة التدفئة والتهوية وتكييف الهواء (HVAC) دون التدريب المناسب والشهادة.',
            'تعديل أو العبث بمكونات التدفئة والتهوية وتكييف الهواء، بما في ذلك الأسلاك الداخلية وخطوط التبريد.',
            'تثبيت أجزاء أو مكونات ما بعد البيع غير المعتمدة والتي قد تؤثر على كفاءة النظام.',
            'تفكيك أو تنظيف مكونات التدفئة والتهوية وتكييف الهواء الداخلية دون توجيهات مهنية.',
            'إهمال جداول الصيانة الدورية، مما يؤدي إلى أعطال محتملة للنظام.',
            'استخدام مبردات غير مصرح بها أو محاولة إعادة شحن النظام دون الحصول على الشهادة المناسبة.',
        ],
    },
    faq: {
        title: "سؤال وجواب المستخدمين:",
        faqItems: [
            {
                question: 'كم مرة يجب عليّ صيانة نظام التدفئة والتهوية وتكييف الهواء (HVAC) الخاص بي؟',
                answer: 'يوصى بصيانة نظام التدفئة والتهوية وتكييف الهواء (HVAC) الخاص بك سنويًا لضمان الأداء الأمثل وطول العمر.',
            },
            {
                question: 'ما هي العلامات التي تشير إلى أن نظام التدفئة والتهوية وتكييف الهواء (HVAC) الخاص بي يحتاج إلى إصلاح؟',
                answer: 'تشمل العلامات ضوضاء غير عادية، وانخفاض تدفق الهواء، والتحكم غير المتسق في درجة الحرارة.',
            },
            {
                question: 'كيف يمكنني تحسين كفاءة استخدام الطاقة في نظام التدفئة والتهوية وتكييف الهواء (HVAC) الخاص بي؟',
                answer: 'لتعزيز كفاءة الطاقة، قم بتنظيف أو استبدال مرشحات الهواء بانتظام وفكر في الترقية إلى منظم حرارة قابل للبرمجة.',
            },
            {
                question: "ما هو العمر الافتراضي لنظام التدفئة والتهوية وتكييف الهواء (HVAC)؟",
                answer: 'يبلغ متوسط ​​عمر نظام التدفئة والتهوية وتكييف الهواء (HVAC) حوالي 15 إلى 20 عامًا، اعتمادًا على الصيانة والاستخدام.',
            },
            // Add more FAQ items as needed
        ],
    },
};

export { termsContentArabic }