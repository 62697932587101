const privacyContentArabic = {
    title: "سياسة الخصوصية",
    breadcrumb: [
        { name: "Etqan Nafal", className: "breadcrumb-item", link: "/" },
        { name: "صفحة", className: "breadcrumb-item", link: "#" },
        { name: "خصوصية", className: "breadcrumb-item active" }
    ],
    introduction: {
        title: "مقدمة :",
        content: "تحدد سياسة الخصوصية الخاصة بنا كيفية جمع معلوماتك الشخصية وتخزينها واستخدامها. يتضمن ذلك البيانات المرتبطة بشخص معين والمستخدمة لتحديد الهوية. المعلومات مجهولة المصدر، التي لا يمكنها التعرف على شخص معين، لا تعتبر معلومات شخصية.",
    },
    collectionInfo: {
        title: "جمعنا للمعلومات الشخصية الخاصة بك  :",
        content: 'عند استخدام الموقع، سنطلب منك تفاصيل شخصية مثل اسمك وعنوانك وبريدك الإلكتروني ورقم هاتفك. للتحقق من هويتك، قد نطلب إثباتًا مثل نسخة جواز السفر. المعلومات المالية مطلوبة في قسم "حسابي" لإعداد الفواتير وتنفيذ الطلب. احتفظ بالتفاصيل الشخصية والمالية في قسم حسابي لمنع الاحتيال. نقوم بجمع بيانات المعاملات بناءً على أنشطة موقعك. يساعدنا عنوان IP الخاص بك على تحليل الاتجاهات وإدارة الموقع. قد نقوم بجمع معلومات إضافية، مثل تفاعلك مع دعم العملاء أو ردود الاستبيان، لتعزيز تجربتك.',
    },
    useInfo: {
        title: "استخدامنا لمعلوماتك الشخصية :",
        content: "نحن نستخدم معلوماتك الشخصية لتقديم الخدمات والدعم وتعزيز تجربتك. ويشمل ذلك منع الأنشطة غير القانونية والامتثال لاتفاقية المستخدم الخاصة بنا. يجوز لنا مشاركة المعلومات مع أطراف ثالثة للتحقق ومنع الاحتيال. على الرغم من أننا نعطي الأولوية للخصوصية، إلا أن الكشف عنها قد يحدث في المواقف القانونية أو مواقف إنفاذ القانون.",
        content2: "إذا تم بيع نافال، فقد تتم مشاركة معلوماتك لمواصلة توفير الموقع. قد يتم الكشف عن البيانات الشخصية في معاملات السلعة، ولكن بخلاف ذلك، لا يتم ذلك إلا بإذن صريح منك. نحن لا نبيع أو نؤجر المعلومات الشخصية ونشاركها فقط على النحو المبين في هذه السياسة.",
        content3: "من خلال التسجيل، فإنك توافق على تلقي رسائل البريد الإلكتروني الترويجية. يمكنك إلغاء الاشتراك في أي وقت. يمكن استخدام تعليقاتك حول الموقع للتسويق، بموافقتك الصريحة.",
    },
    userPersonalInfo: {
        title: "استخدامك لمعلوماتك الشخصية ومعلومات المستخدمين الآخرين :",
        content: "قد يشارك أعضاء الموقع التفاصيل الشخصية والمالية للمعاملات، ومن الضروري احترام خصوصيتهم. لا يمكننا ضمان خصوصية معلوماتك عند مشاركتها مع أعضاء آخرين، لذا تحقق دائمًا من سياسات الخصوصية والأمان الخاصة بهم قبل المشاركة. لا تغطي هذه السياسة إصدار معلوماتك الشخصية لعضو آخر. توافق على استخدام المعلومات المشتركة للمعاملة فقط، وفقًا للقوانين المعمول بها. إن التزامك باحترام الخصوصية يضمن وجود مجتمع آمن وجدير بالثقة.",
    },
    accessingInfo: {
        title: "الوصول إلى معلوماتك الشخصية ومراجعتها وتعديلها :",
        content: "قد يحتوي الموقع على روابط لمواقع أخرى. يرجى العلم بأننا لسنا مسؤولين عن ممارسات الخصوصية لهذه المواقع الأخرى. نحن نشجعك عندما تغادر الموقع على قراءة بيانات الخصوصية لكل موقع ويب تزوره إذا كنت تنوي تقديم معلومات شخصية لذلك الموقع. تنطبق سياسة الخصوصية الخاصة بنا فقط على معلوماتك الشخصية التي نجمعها على الموقع.",
    },
    thirdPartyLinks: {
        title: "روابط مواقع الطرف الثالث :",
        content: "قد يحتوي الموقع على روابط لمواقع أخرى. يرجى العلم أننا لسنا مسؤولين عن ممارسات الخصوصية لهذه المواقع الأخرى...",
    },
    cookies: {
        title: "الكوكيز :",
        content: 'يستخدم الموقع تقنية "ملفات تعريف الارتباط"، وهي ملفات صغيرة موجودة على القرص الصلب بجهاز الكمبيوتر الخاص بك. تعمل ملفات تعريف الارتباط هذه، التي يتم تحديدها برقم عشوائي فريد، على تحسين تجربة موقعك وتقديم نظرة ثاقبة للأقسام الشائعة. لا يكشفون عن المعلومات الشخصية. يمكنك رفض ملفات تعريف الارتباط إذا كان متصفحك يسمح بذلك، ولكن قد يؤثر ذلك على استخدامك للموقع. يضمن تحكمك في تفضيلات ملفات تعريف الارتباط تجربة تصفح مخصصة وفعالة.',
    },
    noSpam: {
        title: "لاتوجد رسائل بريد الكتروني عشوائية :",
        content: 'نحن لا نتسامح مع البريد العشوائي. للإبلاغ عن رسائل البريد الإلكتروني العشوائية أو رسائل البريد الإلكتروني المزيفة المتعلقة بالموقع، يرجى إعادة توجيه البريد الإلكتروني إلى "info@nafal-hvac.sa".',
    },
    protectingInfo: {
        title: "حماية معلوماتك الشخصية :",
        content: "من خلال تزويدنا بمعلوماتك الشخصية، فإنك تقر وتوافق على نقل هذه المعلومات خارج حدود المملكة العربية السعودية...",
    },
    contactUs: {
        title: "كيف يمكنك الاتصال بنا بشأن أسئلة الخصوصية :",
        content: 'إذا كانت لديك أسئلة أو مخاوف بشأن جمع واستخدام معلوماتك الشخصية، يرجى الاتصال بفريق دعم العملاء لدينا على "info@nafal-hvac.sa".',
    },
};

export { privacyContentArabic }