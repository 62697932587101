const vrfContentArabic = {
    title: "تكييف VRV | VRF",
    breadcrumb: [
        { name: "Etqan Nafal", className: "breadcrumb-item", link: "/" },
        { name: "الصفحة", className: "breadcrumb-item", link: "#" },
        { name: "تكييف VRF", className: "breadcrumb-item active" }
    ],
    buttons: [
        { to: "/contact-us", label: "اتصل بنا", className: "btn btn-primary mt-2 me-2" },
        { to: "/services", label: "خدمات", className: "btn btn-outline-primary mt-2" },
    ],
    introductionTitle: "مقدمة",
    introduction: "يعتبر نظام تكييف VRF واحداً من أحدث وأكثر أنظمة التكييف تطوراً ويأتي اختصار تسميته الى (Variable Refrigerant Flow) تدفق التبريد المتغير، وتعني التحكم والمرونة الفائقة في تبريد الفراغات بالمبنى بشكل مستقل، مع التحكم بدرجات التبريد لكل مكان على حده، او تبريد جزء وغلق جزء اخر.",
    tableOfContentsTitle: "جدول المحتويات",
    tableOfContents: [
        "ما هو نظام تكييف VRF؟",
        "مكونات تكييف VRF",
        "مميزات تكييف VRF او VRV",
        "متطلبات تركيب نظام تكييف VRF",
        "تطبيقات نظام تكييف VRF",
        "تكلفة نظام تكييف VRF",
        "استطاعات تكييف VRF",
        "نقاط مهمة عن تكييف VRF",
        "صيانة تكييف VRF",
        "اختيار النظام المناسب",
    ],
    whatIsVrf: {
        title: "ما هو نظام تكييف VRF؟",
        text: [
            "يعمل نظام تكييف VRF على تشغيل عدة وحدات داخلية بأماكن منفصلة او مساحات كبيرة “بوحدة خارجية واحدة”",
            "مع إمكانية تعدد واختيار نوع الوحدة الداخلية مثل (المخفي، او الكاسيت، او الجداري) كما يمكن ربط “الوحدات الخارجية” مع بعضها لعمل سيستم متكامل يغذي مساحات واسعة او أدوار متكررة مثل الفنادق والابراج",
            "توالت الشركات بإنتاج النظام لكن باختلاف المسميات حسب نوع الماركة، مثل ماركة “دايكن VRV “ولكن يبقى عمل النظام واحد."
        ]
    },
    components: {
        title: "مكونات تكييف VRF",
        types: [
            {
                title: "وحدة خارجية (المجموعة الخارجية)",
                text: [
                    "والتي تحتوي على الضاغط وصمام التمدد ولوح الكترونية والتي توضع خارج المبنى كأسطح البنايات.",
                    "يمكن ربط الوحدات الخارجية لعمل سيستم يغذي منطقة او دور كامل.",
                ],
            },
            {
                title: "وحدات تكييف داخلية (المجموعة الداخلية)",
                text: [
                    "وتحتوي على مراوح دفع الهواء والمبادل الحراري المسؤول عن التبريد.",
                    "إمكانية تعدد واختيار نوع الوحدة الداخلية مثل (الدكت كونسيلد، او الكاسيت، او الجداري)",
                ],
            },
            {
                title: "مواسير النحاس",
                text: [
                    "وهي الانابيب التي تربط الوحدة الداخلية بالخارجية والتي يمر بها سائل التبريد “الفريون”",
                    "أطوال شبكة النحاس كبيرة، والتي تصل إلى 1000 متر بين الوحدة الخارجية والوحدة الداخلية.",
                ],
            },
            {
                title: "نظام التحكم",
                text: [
                    "يتيح نظام التحكم الذكي ضبط درجات الحرارة وإدارة تشغيل الوحدات بشكل مستقل.",
                ],
            },
        ]
    },
    features: {
        title: "مميزات تكييف VRF او VRV",
        types: [
            {
                title: "توفير الطاقة",
                text: "نظام تكييف VRF او VRV يوفر الطاقة بنسبة تصل الى 50% مقارنة بأنظمة التكييف التقليدية، وذلك بفضل استخدام تقنية الانفرتر والتبديل الحراري والتحكم الدقيق في معدل تدفق الفريون حسب الحاجة والظروف المحيطة.",
            },
            {
                title: "توفير المساحات",
                text: "نظام تكييف VRF او VRV يوفر في المساحات المستخدمة حيث يقلل من عدد الوحدات الخارجية مما يوفر حلول في البنايات والابراج المرتفعة على عدم تخصيص دور للخدمات التي توضع به الوحدات الخارجية.",
            },
            {
                title: "لتحكم الكامل",
                text: "نظام تكييف VRF او VRV يعمل على تبريد أو تدفئة كل غرفة أو منطقة على حدة، وضبط درجة الحرارة والوضع والوقت والإعدادات الأخرى بسهولة وسرعة.",
            },
            {
                title: "شبكة النحاس",
                text: "يتميز نظام تكييف VRF او VRV بأطوال شبكة النحاس الكبيرة، التي تصل إلى 1000 متر بين الوحدة الخارجية والوحدة الداخلية، وإلى 150 متر بين الوحدة الخارجية وأعلى نقطة في الشبكة، وإلى 40 متر بين الوحدة الخارجية وأقل نقطة في الشبكة. هذه الأطوال تسمح بتركيب الوحدات الخارجية في مكان بعيد عن الوحدات الداخلية، وتوفير المساحة والمظهر الجمالي للمبنى.",
            },
            {
                title: "الصيانة",
                text: "نظام تكييف VRF او VRV يوفر الصيانة والتشخيص السهل والسريع، حيث يمكن اكتشاف وتحديد الأعطال والأخطاء الكترونيا عن طريق النظام، وإرسال التنبيهات والتقارير عن حالة النظام وأدائه، ويمكن ربط النظام بالأنترنت أو الجوال للتحكم عن بعد والحصول على الدعم الفني.",
            },
            {
                title: "المرونة بالتصميم",
                text: "نظام تكييف VRF او VRV يتميز بالمرونة في التصميم والتركيب، حيث يمكن توصيل عدد كبير من الوحدات الداخلية بالوحدة الخارجية، واختيار الوحدات الداخلية المناسبة لكل غرفة أو منطقة، وتوزيعها بشكل متناسق ومتوازن على الشبكة. كما يمكن التحكم في كل وحدة داخلية بشكل منفصل أو مجمع، واستخدام أنظمة التحكم المركزي أو اللاسلكي أو الذكي لضبط درجة الحرارة والوضع والوقت والإعدادات الأخرى.",
            },
        ]
    },
    installation: {
        title: "متطلبات تركيب نظام تكييف VRF",
        boldText: "يشترك نظام تكييف VRF مع انظمة DX المباشر في بعض متطلبات التشغيل مثل:",
        types: [
            { title: "التصميم المسبق", text: "لمخططات التكييف والثي توضح أماكن الوحدات الداخلية والخارجية ومسارات الدكت وفتحات التهوية “الجريلات”وتمديد مواسير النحاس." },
            { title: "ارتفاع الاسقف", text: " عند اختيار وحدات داخلية من نوع “دكت كونسيلد مخفي” تشغل الوحدات الداخلية ومسارات الدكت حيزا بالسقف يتراوح ما بين (30سم الى 50 سم) ثم اعمال الجبس والتي أيضا تشغل تقريبا (10سم) فيجب مراعاة ارتفاع السقف بأن لا يقل عن (3.50متر) تقريبا، لاستيعاب كل الارتفاعات" },
            { title: "البدء بالتأسيس في الوقت المناسب", text: "حيث يتم التأسيس للثرموستات وتعليم الفتحان بالجدران لمسارات الدكت وتأسيس صرف المكيفات." },
            { title: "متطلبات معمارية وإنشائية", text: "حيث يتم تمديد شبكة النحاس عن طريق مناور الخدمة، كون شبكة نحاس تكييف VRF بها توصيلات واقطار مختلفة تكون بحاجة الى الرؤية المباشرة او الفحص في حالة حدوث اي عطل" },
        ]
    },
    applications: {
        title: "تطبيقات نظام تكييف VRF",
        boldText: "يمكن استخدام نظام تكييفVRF في مجموعة متنوعة من التطبيقات، بما يتناسب مع متطلبات واحتياجات كل مشروع",
        types: [
            { title: "المباني التجارية", text: " يستخدم تكييف VRV على نطاق واسع في المباني التجارية مثل الفنادق، الأبراج، المعارض، والمدارس، وغيرها من المشاريع التجارية." },
            { title: "المباني السكنية", text: "يستخدم تكييف VRF أيضا في “الفلل والقصور” مع مراعاة فرق التكلفة المبدئية للتركيب." },
        ]
    },
    cost: {
        title: "تكلفة نظام تكييف VRF",
        text: [
            "تزيد تكلفة نظام تكييف VRV عن المكيفات DX بنسبة تتراوح من 70:80% مع الاخذ في الاعتبار بإمكانية استرداد فرق التكلفة خلال مدة تتراوح من سنتين الى أربع سنوات بحسب ظروف التشغيل،",
            "ويرجع ذلك لقدرة النظام على التوفير في تكاليف التشغيل (فواتير استهلاك الكهرباء) الذي يصل حتى 50% مقارنة بنظام DX",
            "علماَ بان العمر الافتراضي لنظام VFR اطول من نظام DX بحوالي عشر سنوات طبقا لظروف التشغيل",
        ]
    },
    capabilities: {
        title: "استطاعات تكييف VRF",
        text: [
            "يتميز نظام تكييف VRF بالمرونة في تحديد القدرة التبريديه، حيث يمكن يبدأ من “0.5طن” وهو الذي يعد غير متوفر بنظام DX حيث يكون بحد أدنى للمخفي “1.5طن” وللجداري ",
            "ايضاً يوفر تكييف VRF استطاعات عالية يمكن ان تصل حتى “16طن” والتي تكون في نظام DX بحد اقصى “5طن” للمخفي",
            "سهولة التحكم بتقسيم القدرات التبريديه بشكل سلسل وعملي مما يمنع هدر القدرة التبريديه في الاماكن الصغيرة، وتقليل التكلفة في الاماكن الكبيرة بإضافة وحدة داخلية واحدة تصل حتى “16طن” دون اضافة أكثر من وحدة داخلية."
        ]
    },
    conditioning: {
        title: "نقاط مهمة عن تكييف VRF",
        text: [
            "يمكن ان يعمل تكييف VRF بنظامين: النظام الحراري (Heat Pump) الذي يوفر تبريد أو تدفئة لجميع الوحدات الداخلية في نفس الوقت، أو النظام التبديلي (Heat Recovery) الذي يوفر تبريد أو تدفئة لكل وحدة داخلية على حدة، ويسترجع الحرارة الزائدة من الوحدات الداخلية التي تعمل على التبريد ويستخدمها لتدفئة الوحدات الداخلية التي تعمل على التدفئة، وبالتالي يزيد من توفير الطاقة والراحة الحرارية.",
            "نظام تكييف VRF يحتاج إلى تكلفة ابتدائية عالية مقارنة بأنظمة التكييف التقليدية، وذلك بسبب تعقيد النظام وحاجته إلى مواد، ومعدات، وإكسسوارات خاصة، ومتطورة",
            "ويعتبر حلاً متقدمًا وفعالًا لتوفير الطاقة وراحة المستخدم، يعتبر هذا النظام خيارًا جذابًا لأصحاب المباني التجارية والسكنية الكبيرة.",
        ]
    },
    maintenance: {
        title: "صيانة تكييف VRF",
        text: [
            "تعمل الوحدات الخارجية لنظام تكييف VRF بنظام ذكي للتحكم في تشغيل الضواغط “الكمبريسورات” بطريقة تبادلية مما يزيد العمر الافتراضي للضواغط،",
            "وفي حالة تعطل أحد الكمبريسورات يتم نقل الحمل المفقود لباقي الضواغط بطريقة اوتوماتيكية دون توقف النظام او التأثير على عمل النظام ولحين انتهاء اعمال الصيانة،",
            "يعتبر نظام تكييف VRV نظام اعتمادي وذكي مما يحتاج الى فريق صيانة “محترف” للتعرف على اكواد الاعطال وحلها في أسرع وقت",
        ]
    },
    system: {
        title: "اختيار النظام المناسب",
        text: [
            "عند اختيار نظام تكييف VRF، يجب أخذ العديد من العوامل في الاعتبار، التكلفة المالية للنظام، حجم المبنى، والاستخدام المخطط للنظام، وأهداف الراحة الشخصية. من الجيد استشارة متخصص في مجال التبريد للمساعدة في اتخاذ القرار الصحيح.",
            "نسعى دائماً الى تقديم حلول وخدمات فريدة مصممه خصيصاَ وفق احتياجات عملائنا، لتبدأ رحلتنا من حيث انتهى الاخرون لنقدم لعملائنا “حلول مبتكرة …. لحياة أسهل ",
            "لا تترد لطلب عرض سعر، أو أي استفسار عن نظام تكييف VRF او أي نظام تكييف اخر، مهندسينا في انتظار طلبك، ومساعدتك. فقط اضغط على زر “اتصل بنا”، او تحدث مع مهندسنا عبر واتساب"
        ]
    },
}

export { vrfContentArabic }